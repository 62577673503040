/*******************************
Keyframes
*******************************/

// Show

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// Loading Spinner

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Responding Dots

@keyframes dots {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.6);
  }
  100% {
    transform: scale(1);
  }
}

// Conversation list loading

@keyframes sessionListItemLoading {
  0% {
    left: 0;
  }

  100% {
    left: calc(100% - 80px);
  }
}

// Message incoming

@keyframes messageIn {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

// "Mascot is responding

@keyframes respondingIn {
  0% {
    bottom: 100%;
    opacity: 0;
  }
  100% {
    bottom: calc(100% + 5px);
    opacity: 1;
  }
}

// General container animation (also used for modals)

@keyframes showContainer {
  0% {
    bottom: -60px;
    transform: scale(.8);
    opacity: 0;
  }
  100% {
    bottom: 0;
    transform: scale(1);
    opacity: 1;
  }
}

// Modal Overlay

@keyframes modalOverlayIn {
  0% {
    background-color: rgba($color-bg-dark, 0);
  }
  100% {
    background-color: rgba($color-bg-dark, .75);
  }
}

// Drawers

@keyframes openDrawerRight {
  0% {
    transform: translateX(100%)
  }
  100% {
    transform: translateX(0)
  }
}

@keyframes openDrawerLeft {
  0% {
    transform: translateX(-100%)
  }
  100% {
    transform: translateX(0)
  }
}

// Popover

@keyframes popoverTop {
  0% {
    transform: translateY(-15px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes popoverBottom {
  0% {
    transform: translateY(15px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes popoverLeft {
  0% {
    transform: translateX(15px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

// Sidebar active state

@keyframes tabActive {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  85% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}