/*******************************
Edit Document
*******************************/

.edit-doc {
  .edit-doc-header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    position: relative;

    .edit-doc-title {
      min-width: 0;
      > h4 {
        margin: 0 0 10px;
        word-wrap: break-word;
      }
    }

    .edit-doc-label {
      display: flex;
      align-items: center;
      background-color: $color-warn;
      border-radius: 13px;
      color: white;
      font-size: 12px;
      font-weight: $medium;
      height: 26px;
      padding: 0 10px;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -100%);
      opacity: 0;
      transition: transform 200ms, opacity 200ms;

      &.editing {
        transform: translate(-50%, 30px);
        opacity: 1;
      }
    }

    .edit-doc-actions {
      display: flex;
      align-items: center;

      > button {
        margin: 0;
      }

      > i {
        cursor: pointer;
        font-size: 22px;
        padding: 5px;
        margin-left: 15px;

        &:hover {
          color: $color-text;
        }
      }
    }
  }

  .content-wrapper {
    .doc-canvas {      
      &.editing {
        position: relative;
  
        &::after {
          box-shadow: 0 0 2px 1px $color-warn;
          border-radius: 4px;
          display: block;
          content: "";
          inset: -30px;
          position: absolute;
          z-index: 1;
        }
      }
  
      p.doc-canvas-text {
        white-space: pre-line;
        word-wrap: break-word;
        outline: none;
        position: relative;
        z-index: 2;
      }
    }
    
    .json-to-table {
      overflow-x: auto;
    }
  }
}