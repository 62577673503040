/*******************************
Toastify Overrides
*******************************/

.Toastify {
  .Toastify__toast {
    font-family: $font-family-lexend;
    font-size: 15px;
    border-radius: 14px;
    
    .Toastify__progress-bar {
      height: 0;
      visibility: hidden; // I don't think the progress bar is necessary.
      // animation-direction: reverse !important;
    }
  
    .Toastify__toast-icon {
      margin-inline-end: 15px;
    }
    
    .Toastify__close-button {
      margin: auto 10px auto 0;
    }
  }
}