/*******************************
Mascot Guest Sign In
*******************************/

.guest-auth-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.dark-theme {
    background-color: $color-bg-dark;
  }

  &.light-theme {
    background-color: $color-off-white;
  }

  .signin-form-wrapper {
    background-color: white;
    box-shadow: 0 5px 40px -3px rgba(0,0,0,.15);
    color: black;
    border-radius: 24px;
    width: 100%;
    max-width: 480px;
    padding: 72px 40px 40px;
    text-align: center;
    position: relative;

    img.mascot-image {
      background-color: white;
      border-radius: 50%;
      border: 8px solid white;
      box-sizing: content-box;
      width: 82px;
      height: 82px;
      position: absolute;
      top: -28px;
      left: 50%;
      transform: translateX(-50%);
    }

    .signin-form {
      h2 {
        margin: 0 0 5px;

        + p {
          margin: 0 0 25px;
        }
      }

      .input-group {
        margin: 15px 0;
      }

      input.mono {
        font-size: 21px;
        letter-spacing: 5px;
      }
    }

    button {
      color: white;
      margin: 15px 0;
      width: 100%;
    }
  }
}