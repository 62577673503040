/*******************************
Connected Apps
*******************************/

.connected-apps {
  h3 {
    color: white;
  }

  .app-card {
    background-color: $color-dark-blue;
    border: .5px solid $color-border-dark;
    border-radius: 24px;
    padding: 35px 40px;
    min-height: 210px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    &.disconnecting {
      opacity: 0.5;

      .loader {
        margin: 0px 40px;
      }
    }

    .card-header {
      border-bottom: 1px solid $color-border-dark;
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      margin-bottom: 20px;

      > img {
        display: block;
        width: 48px;
        height: 48px;
      }

      h3 {
        color: white;
        margin: 0 0 0 20px
      }
    }

    .card-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      p, button, .button {
        margin: 0;
      }

      .slack-button {
        > img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
    }

    &.disabled {
      background-color: rgba($color-dark-blue, .5);
      border-color: rgba($color-border-dark, .5);

      .card-header {
        opacity: .5;
      }

      .card-info {
        p {
          opacity: .5;
        }
      }
    }
  }
}