/*******************************
Mascot Chat Layout
*******************************/

.mascot-wrapper.mascot-chat {
  position: fixed;
  inset: 0;

  .mascot-sidebar {
    position: fixed;
    width: $mascot-sidebar-width;
    inset: 5px auto 5px 5px;
    z-index: 1;

    @include break-max($break-xs - 1) {
      width: $mascot-sidebar-width - 40px;
    }
  }

  .mascot-chat-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    position: absolute;
    inset: 0;
    left: $mascot-sidebar-width + 5px;
    overflow: hidden;
    z-index: 2;
    transition: 
      left 300ms ease-in-out,
      right 300ms ease-in-out;
  
    .not-ready {
      margin: auto;
      text-align: center;
  
      > img {
        display: block;
        height: 62px;
        width: auto;
        margin: auto;
      }
  
      > button {
        display: inline-flex;
        margin: 0 auto;
      }
    }
  }

  // Toggle Sidebar Desktop
  @include break-min($break-lrg + 1) {
    &.sidebar-toggled .mascot-chat-panel {
      left: 0;
    }
  }

  // Toggle Sidebar Mobile
  @include break-max($break-lrg) {
    transition: transform 300ms ease-in-out;

    .mascot-sidebar {
      transform: translateX(calc(-100% - 10px));
    }

    .mascot-chat-panel {
      left: 0;
    }

    &.sidebar-toggled {
      transform: translateX(calc($mascot-sidebar-width + 10px));

      @include break-max($break-xs - 1) {
        transform: translateX(calc(#{$mascot-sidebar-width - 40px} + 10px));
      }
    }
  }

  // Toggle Drawer (Excerpts or Training)
  &.drawer-active {
    .mascot-chat-panel {
      @include break-min($break-lrg + 1) {
        right: calc($drawer-width + 10px);
      }
    }
  }
  
  // Hide Bobbie in mascot chat
  ~ .wiseox-embed-chat {
    display: none !important;
  }
}