/*******************************
Header
*******************************/

.header {
  display: flex;
  align-items: center;
  background: $color-dark-blue;
  border: 1px solid $color-bg-dark;
  border-radius: 12px;
  box-shadow: 0 10px 15px -2px rgba(0, 0, 0, 0.25);
  height: $header-height;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  inset: $header-inset $header-inset auto;
  padding: 0 20px 0 0;
  z-index: 100;

  .logo-wrapper {
    border-right: 1px solid $color-border-dark;
    padding: 0 20px;
    margin-right: 10px;

    > img {
      display: block;
      height: 24px;
      width: auto;
    }
  }

  .header-text {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
  }

  .header-item {
    border-radius: 8px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: $medium;
    text-transform: uppercase;
    text-align: center;
    padding: 7px 10px;
    position: relative;
    user-select: none;

    > i {
      font-size: 16px;
      color: white;
      margin-right: 5px;
    }

    > .icon-chevron-down {
      color: white;
      margin-left: 5px;
      margin-right: 0;
      font-size: 12px;
    }

    &.active,
    &:hover {
      text-decoration: none;
      color: $color-positive;

      > i {
        color: $color-positive
      }
    }

    &.active {
      background-color: $color-slate;
    }
  }

  .orgs-menu {
    left: 0px;
    top: calc(100% + 5px);
    max-height: 500px;
    min-width: 300px;
    overflow-y: auto;

    .filter-orgs {
      position: relative;
      margin: 5px 0;

      > input {
        margin: 0;
        position: relative;
        padding-right: 36px;
        z-index: 1;
      }

      .select-icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        z-index: 2;
      }
    }

    .popover-item {
      flex-direction: column;
      align-items: flex-start;
    
      &:not(:last-child) {
        border-bottom: 1px dashed $color-border;
      }

      &.my-org {
        border-bottom: 3px solid $color-border;
      }

      .meta {
        margin-top: 2px;
      }
    }
  } 

  .header-nav {
    display: flex;
    align-items: center;
    margin-left: auto;
    
    .header-item {
      margin-left: 5px;

      .account-menu {
        .current-user {
          border-bottom: 1px solid $color-border;
          max-width: 240px;
          padding: 5px 0 15px;
          text-transform: none;
          text-align: left;
          @include truncate();
        }

        .terms {
          font-size: 12px;
          padding: 15px 0 5px;
        }
      }
    }
  }
}
