/*******************************
Insights Dashboard
*******************************/

.dashboard-wrapper {
  .dashboard-hero {
    padding-top: $header-height + $header-inset;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;

    &::after {
      background-image: linear-gradient(0deg, #015B92 0%, #983C9E 100%);
      display: block;
      content: '';
      margin-bottom: 40px;
      position: absolute;
      inset: 0;
      opacity: 0.5;
      z-index: -1;
    }

    .insight-header {
      margin-top: 30px;
    }

    .bar-chart {
      border-bottom: 1px solid $color-border-dark;
      padding-bottom: 12px;
    }
  }
  
  .charts-wrapper {
    max-width: 1320px;
    width: 100%;
    margin: 0 auto 60px;
    padding: 0 30px;
  }
}