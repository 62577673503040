/*******************************
Loading Spinner
*******************************/

.loader {
  display: block;
  border-radius: 50%;
  border-width: 3px;
  border-style: solid;
  border-color: rgba($color-border-dark, .20);
  border-top-color: $color-dark-blue;
  width: 20px;
  height: 20px;
  animation: loader 1s linear infinite;
  margin: 30px auto;

  &.dark {
    border-color: $color-border-dark;
    border-top-color: $color-positive;
  }

  &.small {
    width: 14px;
    height: 14px;
  }

  &.large {
    border-width: 4px;
    width: 32px;
    height: 32px;
  }

  &.app-loader {
    position: fixed;
    width: 52px;
    height: 52px;
    border-width: 6px;
    top: calc(50% - 26px);
    left: calc(50% - 26px);
    margin: 0;
  }
}