/*******************************
Treeview FilePicker
*******************************/

.filter-bar {
  display: flex;
  align-items: center;
  margin: 10px 0;

  button.xxsmall {
    margin: 0 4px 0 0;
  }

  + .select-all {
    margin-top: 20px;
  }
}

.treeview-filepicker {
  border: 1px solid $color-border;
  border-radius: 12px;
  padding: 10px 20px;
  max-height: 480px;
  overflow-y: auto;

  ul, li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  ul.tree {

    li.tree-branch-wrapper,
    li.tree-leaf-list-item {

      .tree-node-group {
        padding: 0 0 0 20px;
      }

      .tree-node {
        align-items: center;
        display: flex;
        padding: 8px 0 8px 22px;
        margin: 0 !important;
        position: relative;

        > * {
          position: relative;
          z-index: 1;
        }

        &:hover .hover {
          border-radius: 4px;
          background-color: $color-off-white;
          position: absolute;
          inset: 0;
          z-index: 0;
        }

        .icon-chevron-right,
        .icon-chevron-down {
            cursor: pointer;
            position: absolute;
            left: 0;
            font-size: 14px;
        }

        .loader {
          position: absolute;
          left: 0;
        }

        .checkbox-wrapper {
          margin: 0 10px 0 0;
        }

        svg.checkbox-icon {
          background-color: white;
          border: 1px solid $color-text-light;
          border-radius: 6px;
          cursor: pointer;
          margin-right: 12px;
          width: 20px;
          height: 20px;
          overflow: hidden;
          position: relative;
          
          > path {
            fill: transparent;
            transform: scale(1.2);
            transform-origin: center center;
          }
        }

        &.tree-node--selected svg.checkbox-icon {
          border-color: transparent;

          > path {
            fill: $color-positive;
          }
        }

        .name {
          display: flex;
          align-items: center;
          font-size: 14px;
          flex: 1;
          min-width: 0;

          > a {
            display: block;
            max-width: 100%;
            @include truncate;
          }

          .list-item-icon {
            margin-right: 5px;
          }

          .btn-scan {
            margin-left: auto;
          }
        }
      }
    }
  }
}