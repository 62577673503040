/*******************************
Variables
*******************************/

// Fonts

$font-family-mono: "PT Mono", Courier, monospace;
$font-family-rubik: "Rubik", "Helvetica", sans-serif;
$font-family-lexend: "Lexend", "Helvetica", sans-serif;

$thin: 100;
$xlight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$xbold: 800;
$black: 900;

// Global Sizes

$header-height: 52px;
$chat-header-height: 60px;
$header-inset: 10px;
$session-header-height: 128px;
$tabbar-height: 80px;
$publishbar-height: 52px;
$content-width: 1200px;
$content-width-narrow: 640px;
$side-padding: 40px;
$chat-max-width: 820px;
$mascot-sidebar-width: 280px;
$drawer-width: 340px;
$mascot-list-gutter: 10px;

// Colors

$color-off-white: #f7f7f7;
$color-silver: #f0f1f2;
$color-light-grey: #e6e6e6;
$color-medium-grey: #c2c2c2;
$color-dark-blue: #011f31;
$color-slate: #334e59;

$color-action: #2de279;
$color-positive: #3dbfbf;
$color-warn: #ff8300;
$color-warn-bg: #fff9f1;
$color-danger: #E42D2D;

$color-bg-dark: #010C12;
$color-border-light: #e8ebed;
$color-border: #e0e0e0;
$color-border-dark: $color-slate;
$color-button-dark: #09364A;

$color-text-lighter: #aeaeae;
$color-text-light: #707070;
$color-text: #313131;
$color-text-dark: #000000;
$color-link-light: rgba(white, .7);
$color-link: $color-positive;
$color-field-hover: color.adjust($color-positive, $lightness: 45%);

$color-progress-bar: $color-action;

$color-rank-positive: #2AD496;
$color-rank-negative: #F7652D;
