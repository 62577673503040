.debugger-wrapper-min {
  position: absolute;
  top: 0px;
  background-color: $color-medium-grey;
  padding: 10px;
  cursor: pointer;
  color: black;

  &.right {
    right: 0px;
  }
}

.debugger-wrapper {
  width: 300px;
  position: absolute;
  bottom: 0px;
  top: 0px;
  background-color: $color-medium-grey;
  padding: 10px;

  &.right {
    right: 0px;
  }

  .debugger-btn-bar {
    text-align: right;
    .debugger-btn {
      font-size: 9px;
      display: inline-block;
      padding: 3px 6px;
      margin: 0px;
      margin-left: 4px;
      height: 15px;
    }
  }

  h3 {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .debugger-content {
    color: black;
    word-wrap: break-word;
    overflow: scroll;
    height: calc(100% - 70px);

    & > div {
      label {
        width: 120px;
        display: inline-block;
      }

      pre {
        margin-top: 5px;
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
}
