/*******************************
Global Classes
*******************************/

.meta,
label {
  color: $color-text-light;
  font-size: 13px;
  font-weight: $medium;

  &.small {
    font-size: 12px;
    font-weight: $normal;
  }

  &.large {
    font-size: 14px;
  }

  &.light {
    color: rgba(white, .65);
  }

  &.white {
    color: white;

    > i {
      color: white;
    }
  }
}

a.meta {
  color: $color-link;
}

.error,
.success {
  display: block;
  font-size: 13px;
  font-weight: $medium;
  margin: 15px 0;
}

.warning {
  color: $color-warn;
}

.error {
  color: $color-danger;
}

.danger:not(button):not(.button) {
  color: $color-danger !important;

  * {
    color: $color-danger !important;
  }
}

.success {
  color: $color-action;
}

.icon-close {
  color: $color-text-light;

  &:hover {
    color: $color-text-dark;
  }
}

.alert {
  background-color: $color-bg-dark;
  border: 1px solid $color-border-dark;
  border-radius: 8px;
  padding: 10px 15px;
  margin: 20px 0;

  > * {
    color: rgba(white, .85);
    margin: 0;
  }

  &.org-alert {
    margin-top: 0;

    > span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
    }
  }
}

.code-snippet-custom {
  display: block;
  font-size: 14px;
  width: 100%;
  word-break: break-all;
  margin: 0;
  max-height: 600px;
  overflow: auto;
  padding-right: 20px;
}

.select-all {
  display: flex;
  align-items: center;
  margin: 10px 0;

  .meta {
    cursor: pointer;
    margin: 0 15px 0 0 !important;

    &:hover {
      color: $color-link;
    }
  }
}


.disabled {
  opacity: 0.5;
  pointer-events: none !important;
}