/*******************************
Global "Edit Mascot" styles
*******************************/

.mascot-wrapper.edit-mascot {
  display: flex;
  gap: 0 5px;
  position: fixed;
  inset: $publishbar-height 5px 5px 5px;

  .mascot-sidebar {
    margin-top: 0;
    flex: 0 0 $mascot-sidebar-width;
    position: relative;
    transition: transform 300ms;

    @include break-max($break-lrg) {
      position: absolute;
      inset: 5px auto 5px 5px;
      width: $mascot-sidebar-width;
      transform: translateX(calc(-100% - 10px));
      z-index: 2;

      &.open {
        transform: translateX(0);
      }
    }
  }

  .edit-mascot-panel {
    background-color: white;
    border-radius: 12px;
    color: $color-text;
    padding: 40px 60px;
    flex: 1;
    overflow: auto;
    position: relative;
    z-index: 1;
  
    .edit-mascot-header {
      border-bottom: 1px solid $color-border;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
  
      > img {
        display: block;
        width: 48px;
        height: 48px;
        margin-right: 15px;
      }
  
      > h1 {
        margin: 0 auto 0 0;
      }
  
      > button {
        margin: 0;
      }
    }
  
    h3 {
      display: flex;
      align-items: center;
  
      > img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
  
      > i {
        display: block;
        margin-right: 10px;
      }
    }
  
    .open-sidebar {
      position: absolute;
      top: 48px;
      left: 16px;
  
      @include break-min($break-lrg + 1) {
        display: none;
      }
    }
  
    .content-wrapper {
      margin-top: 40px;
      padding: 0
    }
  
    .content-col {
      > *:first-child {
        margin-top: 0;
      }
    }
  }
}
