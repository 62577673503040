/*******************************
Edit Mascot Data pages
*******************************/

.edit-mascot-panel {
  .data-pane {
    border-radius: 12px;
    border: 1px solid $color-border;
    padding: 10px 30px;
    margin-top: 15px;

    .data-list {
      margin: 20px 0;
  
      > input {
        margin: 10px 0;
      }
  
      .input-combo {
        margin: 10px 0;
      }
      
      .doc-filter-options {
        padding: 15px 17px;
        
        .select {
          margin: 0 0 15px;
        }
  
        .checkbox-wrapper {
          margin: 10px 0 0;
        }
  
        > *:last-child {
          margin-bottom: 0;
        }
      }

      .select-all {
        margin: 20px 0 0;
        padding-bottom: 10px;
        border-bottom: 1px solid $color-border;

        + .list {
          margin: 0;
        }
      }
  
      .list > ul {
        li.list-item {
          .list-item-meta .meta {
            margin: 0;
          }

          .list-item-name {
            display: flex;
            align-items: center;
            min-width: 0;

            > span {
              display: block;
              max-width: 100%;
              @include truncate;
            }
  
            .icon-btn {
              display: none;
              margin-left: 5px;
              text-decoration: none;
  
              > i {
                font-size: 14px;
              }
            }
          }
          
          .checkbox-wrapper .checkbox {
            width: 20px;
            height: 20px;
            flex: 0 0 20px;
  
            > span .icon-checkmark {
              font-size: 16px;
            }
          }
  
          &.clickable:hover {
            .list-item-name .icon-btn {
              display: block;
            }
          }
        }
      }
    }
  
    .file-list-btn-bar {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 20px;

      .file-input,
      button {
        margin: 0 5px 0 0;
        animation: show 200ms;
      }
    }
  }
}
