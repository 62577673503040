/*******************************
Drag and Drop component
*******************************/

.dnd-wrapper {
  background: #fcfcfc;
  border: 2px dashed $color-border;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
  margin-bottom: 15px;
  min-height: 360px;

  > h5 {
    color: $color-text-light;
    margin: 0 0 10px;
  }

  .file-input {
    margin-bottom: 0;
  }

  &.dragged {
    background-color: #e9edf9;
    border: 2px dashed #9797b0;
  }
}
