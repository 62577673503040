/*******************************
Blockquote styles
*******************************/

blockquote {
  background: $color-off-white;
  border: 1px solid $color-border;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 14px;
  line-height: 21px;
  padding: 14px 15px;
  position: relative;
  white-space: pre-line;
  word-break: break-word;

  > i {
    font-size: 16px;
    margin-right: 12px;

    &.icon-thumbs-up-solid {
      color: $color-rank-positive;
    }

    &.icon-thumbs-down-solid {
      color: $color-rank-negative;
    }
  }

  .truncate {
    display: block;
    max-width: 100%;
    @include truncate;
  }

  &::before {
    background-color: $color-medium-grey;
    border-radius: 3px 0 0 3px;
    content: '';
    display: block;
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -1px;
  }

  &.prompt-preview,
  &.response-preview {
    padding: 8px 15px;
    display: block;

    > a {
      position: absolute;
      top: 8px;
      right: 15px;
      font-size: 13px;
      font-weight: $medium;
    }

    > p {
      margin: 2px 0 0 !important;
    }

    + .md-editor-wrapper {
      margin-top: -10px;
    }
  }

  &.response-preview {
    border: none;
    background-color: transparent;

    &::before {
      display: none;
    }
  }
}

.blockquote-pair {
  margin: 20px 0;

  .prompt-preview,
  .response-preview {
    margin: 0;
  }

  .response-preview {
    padding-bottom: 0;
  }
}