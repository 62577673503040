/*******************************
Layout
*******************************/

// Wrapper for pages other than Mascot Chat / Edit
.main-wrapper {
  position: fixed;
  inset: 0;
  overflow: auto;
}

// General content styles
.content-wrapper {
  padding-top: $header-height + $header-inset;
  padding-bottom: 100px;

  .page-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;

    > h1 {
      color: white;
    }

    > h1,
    > button {
      margin: 0;
    }
  }

  &:not(.full-width) {
    @include content-width();
  }

  &.narrow {
    max-width: ($content-width-narrow + $side-padding * 2);
  }

  .content-col {
    > h3:not(:first-child) {
      margin-top: 40px;
    }
  }

  &.col-2 {
    @include break-max($break-lrg) {
      .content-col {
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }

    @include break-min($break-lrg + 1) {
      display: flex;
      justify-content: space-between;

      .content-col {
        width: calc(50% - 20px);
      }
    }
  }
}