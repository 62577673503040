/*******************************
Editor Avatars
*******************************/

.editors {
  display: flex;
  align-items: center;

  .editor {
    background-color: $color-slate;
    border-radius: 50%;
    box-shadow: -1px 1px 2px 0 rgba(0,0,0,.15);
    width: 32px;
    height: 32px;
    cursor: default;
    transform: scale(1);
    transition: 150ms transform;
    position: relative;
    z-index: 1;

    &:hover {
      transform: scale(1.1);
      z-index: 2;
    }

    > span {
      display: block;
      color: white;
      width: inherit;
      height: inherit;
      font-size: 14px;
      line-height: 32px;
      font-weight: $medium;
      text-transform: uppercase;
      text-align: center;
    }

    &.small {
      width: 28px;
      height: 28px;

      > span {
        line-height: 28px;
        font-size: 12px;
      }
    }
  }
}