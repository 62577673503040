/*******************************
Insights Charts
*******************************/

.no-data {
  color: rgba(white, .5);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  margin: 0;
  text-align: center;
}

// Bar chart
.bar-chart {
  margin-bottom: 40px;
  position: relative;
  min-height: 300px;

  > h4 {
    color: white;
    margin: 0 0 20px;

    + .meta {
      margin: -10px 0 20px;
    }
  }

  &.primary {
    canvas {
      max-height: 350px;
    }
  }
  
  &.secondary {
    canvas {
      max-height: 325px;
    }
  }
}

.charts-wrapper {
  margin: 0 auto 30px;
  max-width: 1140px;

  .chart {
    background-color: $color-dark-blue;
    color: $color-off-white;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    height: 380px;
    width: 100%;
    overflow: hidden;
    padding: 30px 0;
    margin: 0 0 30px;
    position: relative;
  
    > h4 {
      color: white;
      text-align: center;
      margin: 0 0 20px;
    }

    .loader.large {
      margin: 0;
      position: absolute;
      top: calc(50% - 16px);
      left: calc(50% - 16px);
    }
  
    .chart-label {
      color: white;
      text-align: center;
      margin: 20px 0 0;
    }
  
    &.pie-chart {
      justify-content: space-between;
      display: block;
      padding: 30px 0;
  
      > canvas {
        max-height: 275px;
      }
    }
  
    &.donut-chart {
      justify-content: space-between;

      .donut-chart-canvas {
        position: relative;
        
        > canvas {
          max-height: 204px;
          pointer-events: none;
        }

        .chart-value {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: absolute;
          inset: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 204px;

          > h2,
          .meta {
            color: white;
            margin: 0;
          }
        }
      }
    }
  
    &.compare-chart {
      justify-content: space-between;

      > canvas {
        max-height: 200px;
        max-width: 280px;
        margin: 0 auto;
      }
    }

    &.table-chart {
      overflow-y: auto;
      padding: 30px;
    }
    
    &.single-value-chart {
      justify-content: space-between;
  
      .chart-circle {
        background-color: $color-bg-dark;
        border-radius: 50%;
        border: 1px solid $color-border-dark;
        width: 180px;
        height: 180px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        &.clickable {
          cursor: pointer;

          &:hover {
            border-color: $color-positive;
          }
        }
  
        .chart-value {
          font-size: 72px;
          line-height: 90px;
          margin-top: -5px;
          font-weight: $bold;
          font-family: $font-family-lexend;
        }
  
        .meta {
          color: white;
        }
      }
    }
  }

  &.col-2 {
    @include break-min($break-med + 1) {
      display: flex;
      justify-content: stretch;
      align-items: center;

      .chart {
        margin: 0 15px;
        width: calc(100% / 2 - 30px);
      }
    }
  }

  &.col-3 {
    @include break-min($break-lrg + 1) {
      display: flex;
      justify-content: stretch;
      align-items: center;
      
      .chart {
        margin: 0 15px;
        width: calc(100% / 3 - 30px);
      }
    }
  }
}