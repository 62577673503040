/*******************************
Publish Bar
*******************************/

.publish-bar {
  // background-color: $color-dark-blue;
  // border: .5px solid $color-border-dark;
  border-radius: 12px;
  display: flex;
  align-items: center;
  height: $publishbar-height;
  position: fixed;
  inset: 0 0 auto;
  padding: 0px 12px;
  z-index: 2;

  .editors {
    margin: 0 0 0 15px;
    
    .editor {
      &:not(:first-child) {
        margin-left: -6px;
      }
    }
  }

  .meta.editing-label {
    color: rgba(white, .5);
    margin: 0 auto 0 12px;
  }

  .saved-label {
    color: $color-positive;
    font-weight: $medium;
    position: absolute;
    width: 100%;
    text-align: center;
    pointer-events: none;
  }
  .publish-options {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: auto;

    .publish-warning {
      display: flex;
      align-items: center;
      margin: 0 12px 0 0;
  
      > i {
        font-size: 16px;
        margin-right: 5px;
      }
    }  

    .segment button:last-child {
      padding: 0;
      width: 28px;

      > i {
        font-size: 12px;
      }
    }

    .popover {
      min-width: 160px;
      padding: 5px 15px;
    }
  }
}