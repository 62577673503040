/*******************************
Insights Charts
*******************************/

.mascot-wrapper.mascot-insights {
  display: flex;
  gap: 0 5px;
  position: fixed;
  inset: 5px;
 
  .mascot-sidebar {
    flex: 0 0 $mascot-sidebar-width;
    position: relative;
  }

  .mascot-insights-panel {
    flex: 1;
    padding: 15px 25px;
    overflow-y: auto;
  }
}