/*******************************
Modal
*******************************/

.modal-wrapper {
  background-color: rgba($color-bg-dark, .75);
  display: flex;
  align-items: center;
  padding: 30px 10px;
  position: fixed;
  inset: 0;
  overflow-y: auto;
  z-index: 200;
  animation: modalOverlayIn 300ms;
}

.modal {
  background-color: white;
  border-radius: 12px;
  color: $color-text;
  animation: showContainer 300ms;
  width: 100%;
  max-width: 640px;
  overflow: auto;
  position: relative;
  padding: 20px 30px;
  margin: auto;

  @include break-min($break-sm + 1) {
    padding: 35px 40px;
  }

  &.small {
    max-width: 480px;
  }

  &.large {
    max-width: 840px;
  }

  &:focus {
    outline: none;
  }

  .modal-top-actions {
    display: flex;
    align-items: center;
    position: absolute;
    top: 16px;
    right: 16px;

    > * {
      margin: 0 0 0 10px;
    }
  }

  .meta + .modal-title {
    margin-top: 5px;
  }

  .modal-title {
    margin: 0 0 20px;

    @include break-max($break-sm) {
      font-size: 21px;
    }
  }

  .modal-content {
    h3, h4, h5, h6 {
      margin: 20px 0 10px
    } 

    p {
      margin: 10px 0;
      // font-size: 15px;

      &:last-of-type {
        margin-bottom: 20px;
      }
    }

    .meta {
      margin: 10px 0;
    }

    .content-wrapper {
      width: 100%;
      max-width: none;
      padding: 0;

      .content-col {
        margin: 0;
      }
    }
    
    > *:last-child,
    .content-wrapper .content-col > *:last-child {
      margin-bottom: 0;
    }
  }

  .modal-actions {
    display: flex;
    align-items: center;
    margin-top: 25px;

    > button,
    > .button {
      margin: 0 10px 0 0;
    }
  }

  &.create-mascot {
    .input-group {
      .generate-btn {
        margin: 0;
        position: absolute;
        top: 35px;
        right: 10px;
        z-index: 2;
      }

      textarea {
        min-height: 52px;
      }
    }
    .content-wrapper.col-2 {
      .content-col {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 40px;
        }

        @include break-min($break-lrg + 1) {
          margin: 0 !important;
          
          &:nth-child(1) {
            width: calc(56% - 20px)
          }
          &:nth-child(2) {
            width: calc(44% - 20px)
          }
        }
      }
    }
  }

  &.picker-modal {
    .picker-search + .filter-bar {
      margin-top: -10px;
    }
  }
}
