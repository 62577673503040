/*******************************
Image and Theme preview
*******************************/

.preview-window {
  background-color: $color-off-white;
  border: 1px solid $color-border;
  border-radius: 14px;
  width: 100%;
  position: relative;
  overflow: hidden;

  + .preview-window {
    margin-top: 15px;
  }

  .preview-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 15px 20px;

    > .meta {
      position: absolute;
      top: 15px;
      left: 15px;
      text-wrap: break-word;

      > a {
        display: block;
        font-weight: normal;
        margin-top: 5px;
        max-width: 100%;
        @include truncate();
      }
    }

    > button, 
    > .button,
    > .file-input {
      position: absolute;
      left: 15px;
      bottom: 15px;
      margin: 0;
    }

    .mascot-image-preview {
      max-width: 260px;

      .mascot-image {
        display: block;
        border-radius: 50%;
        width: 180px;
        height: 180px;
        margin: auto;
      }

      p {
        text-align: center;
      }
    }
  }

  &.square {
    .preview-content {
      position: absolute;
      inset: 0;
    }

    &::after {
      content: "";
      display: block;
      padding-top: 100%;
      width: 100%;
    }
  }

  // Chat preview overrides

  &.mascot-chat {
    .chat-body {
      padding: 0;
      overflow: visible;
  
      .messages-wrapper {
        padding: 0 10px;
  
        .messages {
          padding: 0;
        }
      }
  
      .new-message {
        width: 100%;
      }
    }
  }

  // QR Code styles

  &.qr-code-window {
    .preview-content {
      min-height: 500px;  

      .qr-code {
        > svg {
          display: block;
          height: auto;
          width: 100%;
          max-width: 256px;
        }
      }
    }
  }

  // Code snippet

  &.code-snippet {
    .preview-content {
      padding-top: 15px;
      padding-bottom: 65px;
      align-items: flex-start;

      > .meta {
        display: block;
        position: static;
        margin-bottom: 20px;
        
        // margin: -15px -15px 20px -15px;
        // width: calc(100% + 30px);
      }
    }
  }
}