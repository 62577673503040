/*******************************
Home
*******************************/

.no-mascots {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .build-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $color-dark-blue;
    border: 2px dashed $color-border-dark;
    border-radius: 24px;
    padding: 45px;
    text-align: center;
    margin: auto;
    width: 100%;
    max-width: 380px;

    > img {
      display: block;
      width: 180px;
      height: 180px;
      margin-bottom: 30px;
    }

    > h2 {
      color: white;
      font-size: 32px;
      margin: 0 0 15px;
    }

    > p {
      margin: 0 0 25px;
    }

    > button {
      margin: 0;
    }
  }

  .suspended {
    color: white;
    max-width: 640px;
    text-align: center;
    padding: 0 30px;

    > h2 {
      color: white;
    }

    > .button {
      display: inline-flex;
      margin: 0;
    }
  }
}

.mascot-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(#{$mascot-list-gutter} / -2);

  .mascot-list-item {
    background: $color-dark-blue;
    border: 1px solid transparent;
    border-radius: 24px;
    margin: calc($mascot-list-gutter / 2);
    text-align: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    transition: 200ms border-color;

    &:has(.duplicating) {
      border: 2px dashed $color-border-dark;
      pointer-events: none;

      .loading {
        display: flex;
        align-items: center;
        
        .loader {
          margin-left: 5px;
        }
      }
    }

    .icon-btn {
      position: absolute;
      top: 11px;
      right: 16px;
      z-index: 3;

      .popover {
        min-width: 160px;
      }
    }

    @include break-min($break-med + 1) {
      width: calc(100% / 2 - $mascot-list-gutter);
    }

    @include break-min($break-lrg + 1) {
      width: calc(100% / 3 - $mascot-list-gutter);
    }

    &::after {
      content: "";
      display: block;
      padding-bottom: 126%;
    }

    &:not(.empty) .mascot-list-item-info {
      backdrop-filter: blur(50px);
    }

    .mascot-list-item-info {
      display: flex;
      color: white;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px 30px;
      position: absolute;
      inset: 0;
      z-index: 2;

      .mascot-image {
        border-radius: 50%;
        display: block;
        width: 180px;
        height: 180px;

        @include break-max($break-xlrg) {
          width: 120px;
          height: 120px;
        }
      }

      .stats {
        display: flex;
        margin-top: 15px;

        .stat-label {
          border: 1px solid rgba(white, .5);
          border-radius: 8px;
          display: flex;
          align-items: center;
          text-align: center;
          height: 28px;
          margin: 0 5px;
          padding: 0 8px;
          font-size: 14px;
          font-weight: $semi-bold;
        }
      }

      > h2 {
        margin: 40px 0 10px;
        font-size: 32px;
        font-weight: $semi-bold;
        color: white;
        // @include truncate(); // 3/21 - Commenting out for now
        max-width: 100%;
      }

      > p {
        margin: 0;
        @include truncate();
        max-width: 100%;
      }
    }

    .mascot-list-item-bg {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 0.6;
      position: absolute;
      z-index: 1;
      inset: 0;

      &.empty {
        background-color: $color-dark-blue;
      }
    }

    &:hover {
      border-color: $color-positive;
      text-decoration: none;
    }
  }
}
