/*******************************
Chrome Extension
*******************************/

$extension-bar-height: 40px;

.mascot-extension-bar {
  border-bottom: .5px solid $color-border-dark;
  display: flex;
  height: $extension-bar-height;
  align-items: center;
  position: relative;
  padding: 0 10px 0 15px;
  z-index: 2;

  > * {
    margin: 0 15px 0 0 !important;
    color: white;

    &:last-child {
      margin: 0 0 0 auto !important;
    }
  }

  select {
    @include truncate;
    max-width: 150px;
    padding-right: 15px !important;
  }
}

.mascot-wrapper.chrome-extension {
  z-index: 1;
  top: $extension-bar-height;

  .drawer {
    top: 45px;
  }
}