/*******************************
Insights header
*******************************/

.insight-header {
  border-bottom: 1px solid $color-border-dark;
  color: $color-off-white;
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 30px;
  z-index: 9;

  > .meta {
    color: rgba(white, .5);
    margin-top: 5px;
  }

  .insight-header-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .insight-header-left {
      display: flex;

      > div {
        display: flex;
        align-items: center;
        margin-right: 10px;

        &:first-child {
          margin-right: 20px;
        }

        &:not(:last-child):not(:first-child) {
          &::after {
            background-color: white;
            content: '';
            display: block;
            width: 4px;
            height: 4px;
            margin-left: 10px;
          }
        }
      }
    }

    .insight-header-right {
      display: flex;
      align-items: center;

      .meta {
        color: white;
        margin-right: 12px;
      }

      .select {
        margin: 0;
        min-width: 140px;
      }
    }
  }
}