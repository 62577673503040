/*******************************
Excerpts
*******************************/

.mascot-excerpts {  
  .excerpt {
    .excerpt-title {
      background-color: $color-off-white;
      border-radius: 6px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 5px 10px;
      margin-top: 5px;

      &:hover {
        background-color: $color-light-grey;
      }

      > p {
        margin: 0;
        @include truncate;
      }
    }

    > blockquote {
      margin: 0;
      font-size: 12px;
    }

    &:not(:last-child) {
      margin: 0 0 15px;
    }
  }
}
