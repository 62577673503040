/*******************************
Global "Edit Mascot" styles
*******************************/

.edit-mascot-tabbar {
  background: white;
  border: 0.5px solid $color-border;
  box-shadow: 0 5px 8px -2px rgba(0,0,0,0.12);
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 8px;
  overflow-y: auto;

  .tabbar-item {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    padding: 8px 8px 6px;
    margin-right: 8px;
    font-size: 13px;
    text-align: center;
    white-space: nowrap;
    position: relative;

    &::after {
      background-color: $color-light-grey;
      border-radius: 8px;
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      transform-origin: center;
      opacity: 0;
      z-index: 1;
    }

    > * {
      position: relative;
      z-index: 2;
    }

    > span .meta {
      display: block;
      font-size: 10px;
    }

    i {
      display: block;
      font-size: 20px;
      margin-bottom: 6px;
    }

    img {
      width: auto;
      height: 20px;
      margin-bottom: 6px;
    }

    .combo-icons {
      display: flex;
      margin-bottom: 6px;

      > span {
        margin: 0 5px;
      }

      > img {
        margin: 0;
      }
    }

    &:hover {
      // background-color: $color-light-grey;
      box-shadow: 0 0 0 1px $color-border;
    }

    &.active {
      pointer-events: none;
      // background-color: $color-light-grey;
     
      &::after {
        transform: scale(1);
        opacity: 1;
        animation: tabActive 200ms ease-out;
      }
    }
  }
}
