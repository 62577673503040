/*******************************
Personality Traits picker
*******************************/

.traitfield {
  border: 1px solid $color-border;
  border-radius: 14px;

  > input {
    border: none;

    &:hover {
      background-color: white;
    }
  }

  .suggestions-wrapper {
    border-top: 1px solid $color-border;
    margin: 0 20px 15px;

    .suggestions-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      
      button {
        margin: 0;
        padding: 0;

        > i {
          margin-right: 0;
        }
      }
    }

    .suggestions-items-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -2.5px;

      .button {
        margin: 0 2.5px 5px;
      }
    }
  }
}
