/*******************************
Progress Bar (Used in Training progress and Mascot update)
*******************************/

.progress-bar {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #868686;
  margin-bottom: 20px;
  height: 20px;
  padding: 2px;
  position: relative;

  .progress-perc {
    color: black;
    position: absolute;
    top: 50%;
    transform: translate(calc(-100% - 2px), -50%);
    z-index: 2;
    font-weight: $semi-bold;
  }
  
  > progress {
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    border: none;
    width: 100%;
    height: 14px;

    &[value]::-webkit-progress-bar {
      background-color: white;
      overflow: hidden;
    }

    &[value]::-webkit-progress-value {
      background-color: $color-progress-bar;
      box-shadow: 0 2px 0 2px white;
      overflow: hidden;
    }

    &::-moz-progress-bar {
      background-color: $color-progress-bar;
    }
  }
}