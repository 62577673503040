/*******************************
Vars
*******************************/

@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");

$color-dark-blue: #011f31;
$color-bg-dark: #010C12;
$color-border-dark: #334e59;
$color-positive: #3dbfbf;
$font-family: "Lexend", "Helvetica", sans-serif;

// Breakpoints
$break-xxs: 320px;
$break-xs: 480px;
$break-sm: 640px;
$break-med: 768px;
$break-lrg: 1024px;
$break-xlrg: 1120px;

// Loading Spinner
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes show {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

// Responsive Mixins
@mixin break-min($point) {
  @media screen and (min-width: $point) {
    @content;
  }
}

@mixin break-max($point) {
  @media screen and (max-width: $point) {
    @content;
  }
}

@mixin break-min-height($point) {
  @media screen and (min-height: $point) {
    @content;
  }
}

@mixin break-max-height($point) {
  @media screen and (max-height: $point) {
    @content;
  }
}

/*******************************
Embed Chat
*******************************/

.wiseox-embed-chat {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 99999;

  @include break-max($break-sm) {
    bottom: 20px;
    right: 15px;
  }

  @include break-max($break-xs) {
    bottom: 15px;
    right: 5px;
  }

  * {
    box-sizing: border-box;
  }

  .wiseox-embed-img {
    cursor: pointer;
    position: relative;
    z-index: 1;

    > img {
      background-color: $color-bg-dark;
      box-shadow: 0 4px 8px -2px rgba(black, 0.8);
      border: 1px solid $color-border-dark;
      padding: 3px;
      border-radius: 50%;
      display: block;
      width: 92px;
      height: 92px;
      transition: transform 200ms;
      transform: scale(1);
      position: relative;
      z-index: 1;

      @include break-max($break-sm) {
        padding: 2px;
        width: 68px;
        height: 68px;
      }
    }

    .wiseox-embed-img-label {
      background-color: $color-positive;
      border-radius: 4px;
      color: black;
      font-size: 12px;
      font-weight: 500;
      font-family: $font-family;
      line-height: 1.3;
      padding: 2px 4px;
      position: absolute;
      z-index: 3;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;

      @include break-max($break-sm) {
        font-size: 10px;
      }
    }

    .loader {
      border-radius: 50%;
      border-width: 3px;
      border-style: solid;
      border-color: rgba($color-border-dark, 0.2);
      border-top-color: $color-dark-blue;
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
      position: absolute;
      inset: 0;
      z-index: 2;
      transform: scale(1.2);
      animation: loader 1s linear infinite;

      &.dark {
        border-color: $color-border-dark;
        border-top-color: $color-positive;
      }
    }

    &:hover {
      > img {
        transform: scale(1.2);
      }

      .loader {
        display: none;
      }
    }
  }

  .wiseox-btn-close {
    background-color: rgba(0,0,0,0.65);
    border-radius: 50%;
    cursor: pointer;
    width: 42px;
    height: 42px;
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 15px;
    right: 30px;
    transform: scale(1);
    transition: transform 200ms;
    animation: show 400ms;

    &:hover {
      background-color: rgba(0,0,0,1);
      transform: scale(1.1);
    }

    @include break-max($break-sm) {
      right: 15px;
    }

    @include break-max($break-xs) {
      right: 5px;
    }
  }

  .wiseox-embed-iframe {
    background-color: $color-bg-dark;
    border-radius: 18px;
    box-shadow: 0 15px 30px -2px rgba(black, 0.45);
    border: 1px solid $color-border-dark;
    width: calc(100% - 60px);
    height: calc(100% - 70px);
    max-width: 460px;
    max-height: 720px;
    position: fixed;
    bottom: 65px;
    right: 30px;
    z-index: inherit;
    overflow: hidden;
    transform-origin: bottom right;
    animation: show 300ms;

    @include break-max($break-sm) {
      width: calc(100% - 30px);
      right: 15px;
    }

    @include break-max($break-xs) {
      width: calc(100% - 10px);
      right: 5px;
    }

    > iframe {
      border: none;
      width: 100%;
      height: 100%;
    }
  }

  .wiseox-embed-img-hidden {
    .wiseox-embed-img {
      display: none;
    }

    .wiseox-btn-close {
      display: flex;
    }
  }

  .wiseox-embed-iframe-hidden {
    display: none;
  }
}
