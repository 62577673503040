/*******************************
Pending Files Popover
*******************************/

.popover.pending-tasks {
  position: fixed;
  inset: auto 152px 20px auto;
  width: 100%;
  max-width: 460px;
  height: 360px;
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: height 300ms;
  animation: popoverBottom 300ms;

  > h5 {
    display: flex;
    align-items: center;
    margin: 0;

    .loader {
      margin: 0 10px 0 0;
    }

    > img {
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  > p {
    margin: 15px 0 10px;
    word-wrap: break-word;
  }

  &.no-tasks:not(.finished) {
    display: none;
  }

  &.updating {
    height: 170px;

    .popover-top-actions .expand {
      display: none;
    }
  }

  &.finished:not(.has-error) {
    &:not(.minimized){
      height: 99px;
    }

    .popover-top-actions .expand {
      display: none;
    }
  }

  &.minimized {
    height: 48px;
    padding: 12px 20px;
    transition: height 300ms, padding 300ms;
    overflow: hidden;

    .popover-top-actions {
      top: 10px;
    }
  }

  &.expanded {
    inset: 160px 180px;
    max-width: none;
    width: auto;
    height: auto;
    padding: 35px 40px;

    h5 {
      font-size: 28px;
      letter-spacing: -0.66px;
    }

    @include break-max($break-xlrg) {
      inset: 120px 140px;
    }
    @include break-max($break-lrg) {
      inset: 100px 80px;
    }
    @include break-max($break-med) {
      inset: 80px 60px;
    }
  }

  .update-progress {
    position: relative;

    .progress-bar {
      margin: 0 0 10px;
    }

    .file-count {
      text-align: right;
      position: absolute;
      right: 0;
      top: 30px;
    }

    > button {
      margin: 0;
    }
  }

  .list {
    .list-item {
      height: auto;
      padding: 10px;

      .list-item-icon {
        align-self: flex-start;
        position: relative;
        top: 2px;
      }

      .list-item-name {
        margin-right: 10px;
        min-width: 0;

        .list-item-file-name,
        .meta {
          word-wrap: break-word;
        }

        .meta {
          margin-top: 3px;
        }
      }

      .processing {
        .loader{
          margin: 0;
        }

        .cancel {
          background-color: $color-danger;
          border-radius: 50%;
          display: none;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 20px;
          height: 20px;
          
          > i {
            font-size: 14px;
            color: white;
          }
        }

        &:hover {
          .loader {
            display: none;
          }
          .cancel {
            display: flex;
          }
        }
      }

      .status-icon {
        display: block;
        width: 20px;
        height: 20px;
      }
    }
  }
}