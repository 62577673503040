/*******************************
Markdown Override
*******************************/

.md-editor-wrapper {
  .w-md-editor {
    box-shadow: none;
    border: 1px solid $color-border;
    border-radius: 14px;
  }

  .w-md-editor-area {
    border-radius: 14px;
  }
  
  .w-md-editor-text {
    padding: 20px 25px;
  }

  // .w-md-editor-text-input {
  // }
  
  textarea.w-md-editor-text-input {
    border-radius: 0;
    border: none;

    &:focus {
      box-shadow: none;
    }
  }
}
[data-color-mode*='light'] .wmde-markdown, 
[data-color-mode*='light'] .wmde-markdown-var, 
.wmde-markdown-var[data-color-mode*='light'], 
.wmde-markdown[data-color-mode*='light'],
body[data-color-mode*='light'] {
  --color-fg-default: #313131 !important;
  --color-prettylights-syntax-comment: #313131 !important;
}

.wmde-markdown {
  hr {
    background-color: transparent;
    height: 0;
    margin: 20px 0;
  }
}