/*******************************************
Font Import
*******************************************/

// PT Mono
@import url("https://fonts.googleapis.com/css2?family=PT+Mono&display=swap");

// Rubik
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

// Lexend
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');