/*******************************
Drawer
*******************************/

.drawer {
  background-color: white;
  box-shadow:
    0 7px 13px 0 rgba(black, 0.14),
    0 0 1px 0 rgba(black, .25);
  border-radius: 12px;
  color: $color-text;
  font-family: $font-family-lexend;
  width: $drawer-width;
  position: fixed;
  inset: 5px 5px 5px auto; // Default right
  padding: 20px;
  overflow: auto;
  z-index: 98;
  animation: openDrawerRight 300ms ease-in-out;

  // &:not(.mascot-training) {
  //   @include break-max(1200px) {
  //     animation: openDrawerRight 200ms;
  //     position: fixed;
  //     inset: 5px 5px 5px auto;
  //     height: auto;
  //     width: calc(100% - 10px);
  //     max-width: $drawer-width;
  //     z-index: 98;
  //   }

  //   @include break-max($break-xs) {
  //     max-width: none;
  //   }
  // }

  // &.active {
  //   display: block;
  // }

  .icon-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  > h5 {
    margin-top: 0;
  }
}