/*******************************
Sign in / Sign up
*******************************/

.auth-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: fixed;
  padding: 30px 15px;
  inset: 0;
  overflow: auto;

  h1, h2, h3, h4, h5 {
    color: white;
  }

  @include break-min($break-xs) {
    padding: 30px;
  }

  @include break-min(921px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
  }

  .logo-wrapper {
    @include break-min(921px) {
      position: fixed;
      top: 40px;
      left: 40px;
      z-index: 2;
    }
  
    .logo {
      display: block;
      height: 32px;
      width: auto;
    }

    > a {
      display: flex;
      align-items: center;
      margin: 20px 0 0;
      font-weight: $medium;

      > i {
        color: $color-positive;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  .forgot-link {
    display: block;
    margin-top: 30px;
  }

  .terms-links {
    display: flex;

    > a {
      color: $color-text-lighter;
      font-weight: normal;
      margin: 0 20px 0 0;
    }
  }

  .signin-form-wrapper {
    background-color: $color-bg-dark;
    border-radius: 24px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    width: 100%;
    max-width: 540px;
    margin: 30px auto;
    z-index: 1;

    @include break-min(921px) {
      border-radius: 0;
      width: 100%;
      max-width: 395px;
      margin: 0;
      position: fixed;
      inset: 0 auto 0 0;
    }

    .signin-form {
      width: 100%;

      > h4 {
        margin-top: 0;
      }

      .signup-link-mobile {
        @include break-min(921px) {
          display: none;
        }
      }
    }

    .terms-links {
      margin-top: 30px;

      @include break-min(921px) {
        position: absolute;
        bottom: 30px;
      }
    }
  }

  .signup-cta-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    inset: 0 0 0 395px;
    padding: 0 60px;

    @include break-max(920px) {
      display: none;
    }

    .signup-cta {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 550px;
      width: 100%;

      > h1 {
        font-size: 48px;
        font-weight: $xbold;
        margin: 0 0 20px;

        @include break-min($break-lrg + 1) {
          font-size: 62px;
        }
      }

      > p {
        margin: 0 0 20px;
      }

      > .button {
        margin: 0;
      }
    }
  }

  .signup-form {
    background-color: $color-bg-dark;
    border: 1px solid $color-border-dark;
    border-radius: 24px;
    width: 100%;
    max-width: 540px;
    margin: 30px auto;
    padding: 30px;
    position: relative;
    animation: showContainer 300ms;

    @include break-min(921px) {
      padding: 40px;
      margin: auto;
    }

    label {
      color: white;
    }

    button,
    .button {
      display: inline-flex;
      margin: 0;
    }

    > h1 {
      margin: 0 0 20px;
    }

    > p + button,
    > p + .button {
      margin: 5px 0 0;
    }

    .terms-links {
      margin: 40px 0 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
}