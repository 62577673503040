/*******************************
Edit Mascot Behaviors
*******************************/

.behaviors-fields {
  background: white;
  border: 1px solid $color-border;
  border-radius: 14px;
  padding: 30px;

  .behavior-field {
    align-items: stretch;
    display: flex;
    padding-bottom: 20px;

    &:not(:last-child) {
      border-bottom: 2px dashed $color-border;
      margin-bottom: 20px;
    }

    .input-group {
      margin: 0;
      margin-right: 40px;
      flex: 0 0 calc(70% - 40px);
      
      label {
        margin-bottom: 15px;
      }

      textarea {
        padding: 0 0 0 5px;
        min-height: 100px;
      }
    }

    p {
      margin: 0;
      flex: 0 0 30%;
      align-self: stretch;
    }
  }
}