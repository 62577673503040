/*******************************
Training Mode
*******************************/

// Training Mode Results Drawer

.mascot-training {
  .empty {
    background-color: $color-off-white;
    border: 1px solid $color-border;
    border-radius: 4px;
    padding: 12px 0;
    text-align: center;
  }

  .meta {
    margin: 0 0 10px;
  }

  .progress-bar {    
    > progress {
      &[value]::-webkit-progress-bar {
        background-color: $color-rank-negative;
      }

      &[value]::-webkit-progress-value {
        background-color: $color-rank-positive;
      }

      &::-moz-progress-bar {
        background-color: $color-rank-positive;
        border-right: 2px solid white;
      }

      @-moz-document url-prefix() {
        background-color: $color-rank-negative;
      }
    }
  }

  .rankings {
    .ranked {
      margin: 0 0 10px;
    }
  }
}

// Edit Response Modal

.modal.edit-response {
  max-height: none;
}