/*******************************
Edit Mascot Settings
*******************************/

.edit-mascot-panel {
  .actions-img {
    display: block;
    height: 120px;
    width: auto;
    margin: -10px 0 10px -6px;
  }

  .topics-editor {
    margin-top: -10px;

    .topic-field {
      margin: 0 0 10px;
      position: relative;

      .icon-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;

        > i {
          cursor: pointer;
          font-size: 16px;
        }
      }

      .text-field-combo {
        margin: 0;
        position: relative;
        z-index: 1;
      }
    }
  }
}