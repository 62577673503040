/*******************************
Button Elements
*******************************/

a:hover:has(button) {
  text-decoration: none;
}

button:not(.Toastify__close-button),
.button {
  background-color: $color-light-grey;
  border: 1px solid transparent;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: $medium !important; // helps maintain weight button class is used on an element with other text styles
  height: 40px;
  color: $color-text-dark;
  text-align: center;
  margin: 20px 0;
  padding: 0 14px;
  outline: none;
  transition: background-color 0.2s;
  white-space: nowrap;

  > i {
    font-size: 20px;

    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }
  }

  > img {
    display: block;
    width: 20px;
    height: 20px;

    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }
  }

  .loader {
    // Wrap text in button in a <span>
    
    &:first-child {
      margin: 0 7px 0 0;
    }

    &:last-child {
      margin: 0 0 0 7px;
    }
  }

  &:hover {
    background-color: color.adjust($color-light-grey, $lightness: -10%);
    text-decoration: none;
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  // Sizes

  &.xxsmall {
    border-radius: 6px;
    height: 24px;
    font-size: 10px;
    padding: 0 9px;

    > i {
      font-size: 16px;
    }
  }

  &.xsmall {
    border-radius: 8px;
    height: 28px;
    font-size: 12px;
    padding: 0 10px;

    > i {
      font-size: 16px;
    }
  }

  &.small {
    border-radius: 10px;
    height: 32px;
    font-size: 13px;
    padding: 0 11px;

    > i {
      font-size: 18px;
    }
  }

  &.large {
    border-radius: 14px;
    height: 52px;
    font-size: 17px;
    padding: 0 15px;

    > i {
      font-size: 22px;
    }
  }

  // Colors

  &.white {
    background-color: white;

    &:hover {
      background-color: color.adjust(white, $lightness: -20%);
    }
  }

  &.dark {
    background-color: $color-button-dark;
    color: white;

    &:hover {
      background-color: color.adjust($color-button-dark, $lightness: 20%, $saturation: -30%);
    }
  }

  &.action {
    background-color: $color-action;
    box-shadow: 0 4px 8px -3px rgba(0, 0, 0, 0.2);
    color: black !important; // important to prevent color override in theme

    &:hover {
      background-color: color.adjust($color-action, $lightness: -10%);
    }
  }

  &.positive {
    background-color: $color-positive;
    color: black;

    &:hover {
      background-color: color.adjust($color-positive, $lightness: -10%);
    }
  }

  &.danger {
    background-color: $color-danger;
    color: white;

    &:hover {
      background-color: color.adjust($color-danger, $lightness: -10%);
    }
  }

  &.outline {
    background-color: white;
    border: 1px solid $color-border;
    color: $color-text;

    &:hover {
      background-color: $color-light-grey;
    }

    &.dark {
      color: white;
      background-color: $color-dark-blue;
      border-color: $color-border-dark;

      &:hover {
        background-color: color.adjust($color-dark-blue, $lightness: 10%);
      }
    }
  }

  &.transparent {
    background-color: transparent;
    color: $color-text;
    padding: 0;

    &.light {
      color: white;

      > i {
        color: white;
      }
    }

    &:hover {
      color: $color-link;

      > i {
        color: $color-link;
      }
    }
  }

  // Misc attributes

  &.wide {
    width: 100%;
  }

  &.pill {
    border-radius: 20px;

    &.xsmall {
      border-radius: 14px;
    }

    &.small {
      border-radius: 16px;
    }

    &.large {
      border-radius: 26px;
    }
  }
}

.file-input {
  margin: 15px 0;

  .button {
    margin: 0;
  }

  input[type="file"] {
    width: 0;
    height: 0;
    visibility: hidden;
    position: absolute;
  }
}

.segment {
  display: flex;

  > * {
    margin: 0 .5px !important;
    box-shadow: 0 !important;

    &:first-child {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &:last-child {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

// Specialty buttons - Use <div>, not <button> or <a>

.icon-btn {
  cursor: pointer;
  padding: 4px;
  position: relative;
  border-radius: 8px;

  &[disabled] {
    pointer-events: none;
    opacity: .5;
  }

  > i {
    display: block;
    color: $color-text-light;
    font-size: 20px; 
  }

  > img {
    display: block;
    width: 20px;
    height: 20px;
  }

  &:hover > i {
    color: $color-text;
  }

  &.light {
    > i {
      color: $color-link-light;
    }

    &:hover > i {
      color: white;
    }
  }

  &.neutral {
    background-color: $color-light-grey;

    > i {
      color: $color-text;
    }
  }

  &.small {
    > i {
      font-size: 16px;
    }
  }

  &.spinning {
    animation: loader 1s linear infinite;
  }

  .popover {
    top: 100%;
  }
}

button:not(.Toastify__close-button).btn-scan{
  display: inline-block;
  margin: 0px;
  margin-right: 10px;
}
